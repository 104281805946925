<template>
  <div class="createsearch">
    <h1>This is an about page</h1>
  </div>
</template>

<script>
import {mapMutations, mapState, mapActions, Store} from "vuex";
export default {
    name: "Callback",
    data() {
        return {
            
        }
    },
    computed: {
        ...mapState("user", ["users"]),
    },
    beforeMount() {
       
    },
    async created() {
        try {
            console.log(this.$auth.user.name)
            this.changeUser(this.$auth.user.name);
            this.Carga();
            let user = JSON.parse(localStorage.getItem("user"));
            await this.loadUser()
            console.log(this.users);
            if(this.users){
                const cuentas = await this.axios.get(`/bextsocial/usercuenta/${this.users[0].Id_Usuario}`);
                console.log(this.users[0].Id_Usuario)
                if(cuentas.data){
                    this.Carga();
                    this.Counts(true)
                    const pruebaresponse =  await this.axios.get(`/bextsocial/cuenta/${this.users[0].Id_Usuario}`)
                    console.log(pruebaresponse);
                    let result = [];
                    let map = new Map();
                    pruebaresponse.data.forEach((item, index) => {
                    if(!map.has(item.nombre)){
                            map.set(item.nombre, true); 
                            // set any value to Map maoero
                            result.push({
                                Id_Empresa: item.Id_Empresa,
                                empresa: item.nombre,
                                pais: item.pais,
                                departamento: item.departamento,
                                ciudad: item.ciudad,
                                telefono: item.telefono,
                                contacto: item.contacto,
                                correo: item.correo,
                                view: item.view_empresa,
                                socialweb: new Array()
                            });
                            if(result.length == 1){
                                result[0].socialweb.push({
                                    Id_Usuario: item.Id_Usuario,
                                    Red_Social: item.Red_Social
                                })
                            }
                            else{
                                let indextmp = null;
                                let arr = result.filter(function(x,i){if(x.empresa == item.nombre){indextmp = i}} )
                                result[indextmp].socialweb.push({
                                    Id_Usuario: item.Id_Usuario,
                                    Red_Social: item.Red_Social
                                });
                            }
                            
                        }
                        else 
                        {  
                            let indextmp = null;
                            let arr = result.filter(function(x,i){if(x.empresa == item.nombre){indextmp = i}} )
                            result[indextmp].socialweb.push({
                                Id_Usuario: item.Id_Usuario,
                                Red_Social: item.Red_Social
                            });
                        }
                    })

                    this.socialWeb(result)
                    this.$router.push({ name: 'Home' })

                }
                else{
                    this.Carga();
                    this.Counts(false)
                    this.$router.push({ name: 'Accounts' })
                }
            }
            else{
                const response = await this.axios.post('/bextsocial/user', {
                    email: user.email.trim(),
                });
                await this.loadUser();
                
                const { data } = await this.axios.get("/bextsocial/notifications")
                console.log(data)
                if(data){
                    const vuex = JSON.parse(localStorage.getItem("vuex"));
                    console.log(data)
                    const datos = data[0];
                    for (let index = 0; index < datos.length; index++) {
                        console.log(datos[index])
                        let respuesta = await this.axios.post("bextsocial/notifications",{
                            iduser: vuex.user.users[0].Id_Usuario,
                            idnotification: datos[index].Id_Notificacion,
                            leida: 0,
                            activa: 1
                        })
                        if(index == datos.length -1 ){
                            this.Carga();
                            this.Counts(false)
                            this.$router.push({ name: 'Accounts' })    
                        }
                    }
                    
                }

                
                
            }
        } catch (error) {
            console.log(error)
        }
        
    },
    props: {

    },
    methods: {
         ...mapActions("user",["loadUser"]),
         ...mapActions(["socialWeb"]),
         ...mapMutations(["Carga","Counts"]),
         ...mapMutations("user", ["changeUser"]), 
    },
    async mounted() {
        
    },
}
</script>